import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import queryString from 'query-string';
import { Authenticator as AmplifyAuthenticator } from '@aws-amplify/ui-react';
import Cookies from 'universal-cookie';
import { I18nextProvider } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { ThemeProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import DateFnsUtils from '@date-io/date-fns';
// import theme from '@trurisk/core/styles/theme';
import i18n from '@trurisk/core/i18n';
// import { Button } from '@mui/material';
// import signOut from '@trurisk/core/authentication/signOut';
// import { initializeAmplify, configureAmplify } from '@trurisk/core/authentication/configureAmplify';
import { grabSlimIdentity, removeIdentity } from '@trurisk/core/store/identity/actions';
import store from '@trurisk/core/store/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import '@trurisk/core/styles/styles.scss';

import environment from './config/environment';
import * as serviceWorker from './serviceWorker';
import Authenticator from './authentication/Authenticator';
import { initializeAmplify, configureAmplify } from './authentication/configureAmplify';
import theme from './theme';
import forceSignOut from './forceSignOut';

export interface IAuthInitialValues {
  username: string;
  password: string;
  code: string;
  ready: boolean;
  sme: string;
  tokenExpired: boolean;
}

Sentry.init({
  dsn: 'https://77ff26f787e9431abd27c2ee15c64069@sentry.trurisk.io/6',
  integrations: [new BrowserTracing()],
  environment: environment.env,
  tracesSampleRate: 1.0,
});

const cookies = new Cookies();

// temp cookie handler
const invalidateCognito = async () => {
  const truriskInvalidate = await cookies.get('trurisk_ab');
  if (!truriskInvalidate) {
    const cook = await cookies.getAll();
    const keys = Object.keys(cook);
    keys.forEach((c) => {
      cookies.remove(c);
      cookies.remove(c, { domain: `.${environment.route}`, path: '/' });
      cookies.remove(c, { domain: environment.route, path: '/' });
    });
  }
};
// temp cookie handler

const getInitialValues = (): IAuthInitialValues => {
  const query = queryString.parse(window.location.search) as any;
  const temp = query.temp ? atob(query.temp) : '';
  const currentValues = queryString.parse(temp) as any;

  // if (window.location.pathname === '/logged-out') {
  //   cookies.remove('trurisk_account');
  //   signOut();
  // } else if (currentValues.username) {
  if (currentValues.username) {
    forceSignOut(environment).then(() => {
      configureAmplify(environment, currentValues.username.trim());
    });
  } else {
    initializeAmplify(environment);
  }

  return {
    username: currentValues.username ? currentValues.username.replace(' ', '+') : '',
    password: currentValues.password ? currentValues.password : '',
    code: currentValues.code ? currentValues.code : '',
    ready: true,
    sme: query.sme ? query.sme : '',
    tokenExpired: !!query.tokenExpired,
  } as any;
};

invalidateCognito();
const initialValues = getInitialValues();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <HelmetProvider context={{}}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n(environment.aws.cookie_domain)}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateLibInstance={DateFnsUtils}>
            <SnackbarProvider maxSnack={3}>
              <BrowserRouter>
                <AmplifyAuthenticator.Provider>
                  <Authenticator grabIdentity={grabSlimIdentity} removeIdentity={removeIdentity} initialValues={initialValues} />
                </AmplifyAuthenticator.Provider>
              </BrowserRouter>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  </HelmetProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
