import { Box } from '@mui/material';
import { LinearLoader } from '@trurisk/core/components/cosmetics';
import { FormField, FormFieldTypes } from '@trurisk/core/components/forms';
import { ActionBar } from '@trurisk/core/components/skeleton';
import { signIn } from 'aws-amplify/auth';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

// import { configureAmplify } from '@trurisk/core/authentication/configureAmplify';
import { grabUser } from '@trurisk/core/store/user/actions';
import { useSnackbar } from 'notistack';
import AuthenticationFooter from '../components/authenticationFooter/AuthenticationFooter';
import environment from '../config/environment';
import { IAuthInitialValues } from '../index';
import { configureAmplify } from './configureAmplify';

interface Props {
  initialValues: IAuthInitialValues;
}

const SignIn: React.FC<Props> = ({ initialValues }) => {
  const history = useHistory();
  const [ready, setReady] = useState<boolean>(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!!initialValues.password && !!initialValues.username && initialValues.ready) {
      initialValues.ready = false;
      handleSubmit(initialValues);
    } else if (!!initialValues.code && initialValues.ready) {
      history.push('/new-password', { state: { username: initialValues.username, password: initialValues.password } });
      initialValues.ready = false;
    } else {
      setReady(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async ({ username, password }: any) => {
    await configureAmplify(environment, username.trim());
    try {
      const user = await signIn({
        username: username.trim(),
        password: password.trim(),
        options: {
          authFlowType: 'CUSTOM_WITH_SRP',
        },
      });
      if (user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        grabUser(user);
        history.push({ pathname: '/multi-factor-authentication', state: { username, password } });
      } else if (user?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        history.push({ pathname: '/require-new-password', state: { username, password } });
      }
      // else if (!user.username) {
      //   setReady(true);
      // }
    } catch (err: any) {
      const errorMsg = err?.message || '';
      if (
        errorMsg.indexOf('Password does not conform to policy') > -1 ||
        errorMsg.indexOf('validation error detected') > -1 ||
        errorMsg.indexOf("Value at 'password' failed to satisfy constraint") > -1
      ) {
        enqueueSnackbar(t('Passwords must be at least 6 characters and contain at least one uppercase letter and one number.'), { variant: 'error' });
      } else if (errorMsg === 'User is disabled.') {
        enqueueSnackbar(t('Your account has been disabled. Please contact your administrator or TruRisk support if you need access.'), { variant: 'error' });
      } else if ((errorMsg.includes('User pool client') && errorMsg.includes('does not exist.')) || errorMsg.includes('Auth UserPool not configured.')) {
        enqueueSnackbar('Invalid email or password. Please try again.', { variant: 'error' });
      } else if (errorMsg) {
        enqueueSnackbar(errorMsg, { variant: 'error' });
      }
      setReady(true);
    }
  };

  return (
    <>
      <div className={`authentication-wrapper ${!ready ? 'not-ready' : ''}`}>
        {!ready && <LinearLoader absolute />}
        <div className="authentication-outside-container">
          <div className="authentication-navigation" />
          <div className="authentication-container">
            <div className="authentication-logo">
              <img src="/auth/images/logo/trurisk-logo-black.png" alt="TruRisk" />
            </div>
            <div className="authentication-form">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  username: Yup.string().required(t('Username is a required field.')),
                  password: Yup.string().required(t('Password is a required field.')),
                })}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Box m={2}>
                      <FormField name="username" label={t('Username')} onBeforeChange={(val: string) => val.toLowerCase().trim()} />
                    </Box>
                    <Box m={2} mt={4}>
                      <FormField name="password" label={t('Password')} type={FormFieldTypes.PASSWORD} />
                    </Box>
                    <Box m={2} mt={5}>
                      <ActionBar.Button variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting} loading={isSubmitting}>
                        {t('Log in')}
                      </ActionBar.Button>
                    </Box>
                  </Form>
                )}
              </Formik>
              <Box m={2} mt={4}>
                <NavLink to="/change-password">{t('Forgot password?')}</NavLink>
              </Box>
            </div>
          </div>
        </div>
        <AuthenticationFooter />
      </div>
      {/* )} */}
    </>
  );
};

export default SignIn;
