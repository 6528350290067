import React, { Context } from 'react';
import { Page, PageContent, PageHeader, TitleArea } from '@trurisk/core/components/skeleton';
import { AppContext } from '@trurisk/core/commonTypes';
import Identity from '@trurisk/core/models/identity';
import './LegalWrapper.scss';
import AuthTopBar from './components/auth-topbar/AuthTopBar';

interface Props {
  title?: string;
  subtitle?: string | React.JSX.Element;
  body?: React.JSX.Element;
  footer?: React.JSX.Element;
  identity?: Identity;
  environment: any;
  context: Context<AppContext>;
}

const LegalWrapper: React.FC<Props> = ({ title = '', subtitle = '', body = null, footer = null, identity = null, environment = null, context }) => (
  <div className="legal-wrapper">
    <AuthTopBar identity={identity} context={context} environment={environment} />
    <Page className="legal-wrapper-container">
      {title && subtitle && (
        <PageHeader>
          <TitleArea title={title} subtitle={subtitle} />
        </PageHeader>
      )}
      {body && <PageContent className="legal-wrapper-content">{body}</PageContent>}
      {footer && <div className="legal-wrapper-footer">{footer}</div>}
    </Page>
  </div>
);

export default LegalWrapper;
