import { Grid } from '@mui/material';
import signOut from '@trurisk/core/authentication/signOut';
import { AppContext } from '@trurisk/core/commonTypes';
import { ActionBar } from '@trurisk/core/components/skeleton';
import { CopyModal } from '@trurisk/core/components/standalone';
import Identity from '@trurisk/core/models/identity';
import { generateClient } from 'aws-amplify/api';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import React, { Context } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import GQL from '@trurisk/core/graphql/gqlschema';
import { updatePlatformUser } from '@trurisk/core/graphql/mutations';
import { grabSlimIdentity } from '@trurisk/core/store/identity/actions';
import LegalWrapper from './LegalWrapper';
import { getUtcFormatted } from './helpers';
import './terms-policy.scss';

interface Props {
  context: Context<AppContext>;
  environment: any;
  identity?: Identity;
}

function TermsPage(props: Props) {
  const API = generateClient();
  const { t } = useTranslation();
  const history = useHistory();
  const { identity = {} as Identity, environment } = props;
  const policyPageAddress = `/policy`;

  const title = t('Terms of Use');
  const body = <iframe title={title} src={`https://${environment.aws.cookie_domain}/terms-of-use-uk`} width="100%" height="100%" />;

  const subtitle = (
    <p style={{ lineHeight: '22px' }}>
      {t('To use TruRisk you must first read and agree to the Terms of Use. To access the Privacy Policy, please')}{' '}
      <Link to={policyPageAddress} className="terms-policy-link">
        {t('click here')}
      </Link>
      .
    </p>
  );

  async function submitAccept(input: any) {
    const { id, account } = input;

    try {
      if (id && account) {
        const { errors } = await (API.graphql({
          query: updatePlatformUser,
          variables: { input },
        }) as Promise<GQL.IGraphQLResponseRoot>);

        if (errors) throw errors;

        await grabSlimIdentity();

        history.push('/');
      }
    } catch (error) {
      console.log('error accepting terms: ', error);
    }
  }

  const agreeForm = (
    <Formik
      enableReinitialize
      initialValues={{ tou_accepted: false }}
      validationSchema={Yup.object().shape({
        tou_accepted: Yup.boolean().required(),
      })}
      onSubmit={async ({ tou_accepted }) => {
        await submitAccept({
          id: identity.id,
          account: identity.account_id,
          tou_accepted,
          tou_accepted_at: getUtcFormatted(new Date()),
        });
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className="trurisk-form">
          <Grid container wrap="nowrap">
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="tou_accepted"
              Label={{
                label: t('I have read and agree to the TruRisk Terms of Use'),
              }}
            />
          </Grid>
          <Grid container wrap="nowrap" justifyContent="flex-end">
            <ActionBar.Button onClick={() => signOut(environment)} color="primary" variant="text">
              {t('Go Back')}
            </ActionBar.Button>
            <ActionBar.Button type="submit" color="primary" variant="contained" loading={isSubmitting} disabled={!values.tou_accepted}>
              {t('Accept')}
            </ActionBar.Button>
          </Grid>

          <CopyModal open={isSubmitting} text={`${t('Getting you set up...')}`} />
        </Form>
      )}
    </Formik>
  );

  const backButton = (
    <Grid container wrap="nowrap">
      <ActionBar.Button onClick={() => history.goBack()} color="primary" variant="text">
        {t('Go Back')}
      </ActionBar.Button>
    </Grid>
  );

  const footer = identity?.id && !identity?.tou_accepted ? agreeForm : backButton;

  return <LegalWrapper {...props} title={title} subtitle={subtitle} body={body} footer={footer} />;
}

export default TermsPage;
