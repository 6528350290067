/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import { fetchUserAttributes } from 'aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSnackbar } from 'notistack';
// import { Hub } from '@aws-amplify/core';
import { LinearLoader } from '@trurisk/core/components/cosmetics';
import { Snackbar } from '@trurisk/core/components/skeleton';
import { AppContext } from '@trurisk/core/commonTypes';
import Router from '../Router';
import environment from '../config/environment';
import { IAuthInitialValues } from '../index';
import './Authenticator.scss';
import AuthenticatorRouter from './AuthenticatorRouter';

interface IAuthenticatorProps {
  initialValues: IAuthInitialValues;
  grabIdentity(): Promise<boolean>;
  removeIdentity(): void;
}

const appContext = createContext<AppContext>({} as AppContext);

const Authenticator: React.FC<IAuthenticatorProps> = ({ grabIdentity, removeIdentity, initialValues }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [authState, setAuthtate] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [snackbar, setSnackbar] = useState<any | boolean>(false);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user } = useAuthenticator((context) => [context.user]);
  const [authError, setAuthError] = useState<boolean>(false);
  // console.log('authStatus = ', authStatus)
  if (initialValues.username) {
    history.replace(history.location.pathname);
  }

  useEffect(() => {
    if (initialValues.tokenExpired) {
      enqueueSnackbar(t('Your session has expired, please log in again.'), { variant: 'error' });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // debugger;
    async function fetchMyAPI() {
      if (authStatus === 'authenticated') {
        const authError = await grabIdentity();
        setAuthError(authError);
      }
    }

    fetchMyAPI();

    // eslint-disable-next-line
  }, [authStatus]);

  // useEffect(() => {
  //   Hub.listen('auth', (res) => {
  //     debugger;
  //     console.log(' Hub.listen(auth, (res)  = ', res);
  //     // const errorMsg = res.payload && res.payload.data && res.payload.data.message ? res.payload.data.message : '';
  //     const errorMsg = res?.payload?.message || '';
  //     if (
  //       errorMsg.indexOf('Password does not conform to policy') > -1 ||
  //       errorMsg.indexOf('validation error detected') > -1 ||
  //       errorMsg.indexOf("Value at 'password' failed to satisfy constraint") > -1
  //     ) {
  //       enqueueSnackbar(t('Passwords must be at least 6 characters and contain at least one uppercase letter and one number.'), { variant: 'error' });
  //     } else if (errorMsg === 'User is disabled.') {
  //       enqueueSnackbar(t('Your account has been disabled. Please contact your administrator or TruRisk support if you need access.'), { variant: 'error' });
  //     } else if (errorMsg.includes('User pool client') && errorMsg.includes('does not exist.')) {
  //       enqueueSnackbar('Invalid email or password. Please try again.', { variant: 'error' });
  //     } else if (errorMsg) {
  //       enqueueSnackbar(errorMsg, { variant: 'error' });
  //     }
  //   });
  // }, [enqueueSnackbar, t]);

  // const router = authState !== 'signedIn' ? <AuthenticatorRouter initialValues={initialValues} loader={loader} /> : <Router context={appContext} authError={authError} />;

  let router = <LinearLoader absolute />;

  if (authStatus === 'configuring') {
    router = <LinearLoader absolute />;
  } else if (authStatus === 'unauthenticated') {
    router = <AuthenticatorRouter initialValues={initialValues} />;
  } else if (authStatus === 'authenticated') {
    router = <Router context={appContext} authError={authError} />;
  }

  // console.log('-------------1');
  // console.log('authStatus', authStatus);
  // console.log('authError', authError);
  // console.log('router', router);

  return (
    // <AmplifyAuthenticator hideDefault={true} onStateChange={handleAuthStateChange}>
    <appContext.Provider
      value={{
        breadcrumbObject: {},
        setBreadcrumbObject: () => null,
        environment,
        setSnackbar,
      }}
    >
      <Snackbar open={!!snackbar} setOpen={setSnackbar} {...snackbar} />
      {/* {authState ? router : <LinearLoader absolute />} */}
      {router}
    </appContext.Provider>
    // </AmplifyAuthenticator>
  );
};

export default Authenticator;
