/* eslint-disable @typescript-eslint/naming-convention */
import { Amplify, ResourcesConfig } from 'aws-amplify';
import Cookies from 'universal-cookie';
import isEmpty from 'lodash/isEmpty';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { getCognitoDataUser } from '@trurisk/core/graphql/queries';
import { mapCognitoData } from '@trurisk/core/models/cognitoData';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import getAwsConf from './aws-config';

const cookies = new Cookies();

const initializeAmplify = async (environment: any) => {
  const awsConf = getAwsConf(environment);
  await cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
      domain: environment.aws.cookie_domain,
      path: '/',
      expires: 365,
      secure: environment.aws.cookie_domain !== 'localhost',
      sameSite: 'strict',
    })
  );

  try {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const trurisk_account = (await cookies.get('trurisk_ab')) || {};

    if (!isEmpty(trurisk_account)) {
      awsConf.Auth.Cognito = {
        ...awsConf.Auth.Cognito,
        ...trurisk_account,
      };
    }

    if (!isEmpty(trurisk_account)) {
      await Amplify.configure(awsConf as unknown as ResourcesConfig, {
        API: {
          GraphQL: {
            headers: async () => {
              const session = await fetchAuthSession();
              debugger;
              return { Authorization: session.tokens?.idToken, Accept: 'application/json, text/plain, */*', 'X-Api-Key': environment.aws.aws_appsync_apiKey };
            },
          },
        },
        ssr: true,
      });
    }
  } catch (error) {
    console.log('Amplify initialization error');
    console.log(error);
    return '';
  }
};

const configureAmplify = async (environment: any, username: string) => {
  const awsConf = getAwsConf(environment);
  try {
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CookieStorage({
        domain: environment.aws.cookie_domain,
        path: '/',
        expires: 365,
        secure: environment.aws.cookie_domain !== 'localhost',
        sameSite: 'strict',
      })
    );

    const { data } = await axios({
      url: environment.aws.aws_appsync_graphqlEndpoint,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain, */*',
        'X-Api-Key': environment.aws.aws_appsync_apiKey,
      },
      data: {
        query: getCognitoDataUser,
        variables: {
          username,
        },
      },
    });

    if (!data?.data?.getCognitoDataUser?.user_pool_id) {
      return '';
    }

    const trurisk_account = mapCognitoData(data);
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    await cookies.set('trurisk_ab', trurisk_account, {
      path: '/',
      expires: oneYearFromNow,
      domain: environment.aws.cookie_domain,
      secure: environment.aws.cookie_domain !== 'localhost',
      sameSite: 'strict',
    });

    awsConf.Auth.Cognito = {
      ...awsConf.Auth.Cognito,
      ...trurisk_account,
    };

    await Amplify.configure(awsConf as unknown as ResourcesConfig, {
      API: {
        GraphQL: {
          headers: async () => {
            const session = await fetchAuthSession();
            return { Authorization: session.tokens?.idToken, Accept: 'application/json, text/plain, */*', 'X-Api-Key': environment.aws.aws_appsync_apiKey };
          },
        },
      },
      ssr: true,
    });
    return username;
  } catch (err) {
    return '';
  }
};

export { initializeAmplify, configureAmplify };
