import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import { confirmResetPassword, resetPassword, signIn } from 'aws-amplify/auth';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { PasswordPolicy } from '@trurisk/core/components/cosmetics';
import { FormField, FormFieldTypes } from '@trurisk/core/components/forms';
import { CopyModal } from '@trurisk/core/components/standalone';
import { regex_password } from '@trurisk/core/constants/regex';
import { Icon } from '@trurisk/core/icons';
import { TooltipStyled } from '@trurisk/core/components/skeleton';
import AuthenticationFooter from '../components/authenticationFooter/AuthenticationFooter';
import environment from '../config/environment';
import { IAuthInitialValues } from '../index';
import { configureAmplify } from './configureAmplify';

interface Props {
  initialValues: IAuthInitialValues;
}

interface State {
  state: { [key: string]: string };
}

const ChangeForgottenPassword: React.FC<Props> = ({ initialValues }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  // const historyState = location?.state?.state as { [key: string]: string };
  const historyState = location?.state as State;
  const [loading, setLoading] = useState<boolean>(false);

  const oldValues = {
    username: initialValues?.username || historyState?.state?.username || '',
    password: initialValues?.password || historyState?.state?.password || '',
    code: initialValues?.code || historyState?.state?.code || '',
  };

  useEffect(() => {
    initialValues.ready = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async ({ username, code, password }: any) => {
    await configureAmplify(environment, username.trim());
    try {
      setLoading(true);
      await confirmResetPassword({
        username: username.trim(),
        newPassword: password.trim(),
        confirmationCode: code.trim(),
      });
      await signIn({
        username: username.trim(),
        password: password.trim(),
      });
      setLoading(false);
    } catch (e) {
      const err: any = e;
      setLoading(false);
      if (err.code === 'InvalidParameterException' || err.code === 'NotAuthorizedException') {
        enqueueSnackbar(t('Current Password is invalid. Please try again.'), { variant: 'error' });
      } else if (err.code === 'LimitExceededException') {
        enqueueSnackbar(t('Too many attempts. Please try again later.'), { variant: 'error' });
      } else if (err?.message) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    }
  };

  const resendCode = (username: string) => async (e: any) => {
    e.preventDefault();

    await configureAmplify(environment, username.trim());
    await resetPassword({ username: username.trim() });

    enqueueSnackbar(t('Code sent.'), { variant: 'success' });
  };

  const defaultValues = { password: '', code: '' };

  return (
    <div className="authentication-wrapper">
      <div className="authentication-outside-container">
        <div className="authentication-navigation">
          <NavLink to="/">
            <ChevronLeft />
            {t('Back to Login')}
          </NavLink>
        </div>
        <div className="authentication-container">
          <div className="authentication-logo">
            <img src="/auth/images/logo/trurisk-logo-black.png" alt="TruRisk" />
          </div>
          <div className="authentication-form">
            <Formik
              enableReinitialize
              initialValues={{ ...defaultValues, ...oldValues }}
              validationSchema={Yup.object().shape({
                code: Yup.string().required(t('Confirmation Code is required.')),
              })}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <Box m={2}>
                    <div>
                      <h2>
                        {t('Email sent')}

                        <TooltipStyled
                          placement="bottom"
                          title={
                            <div className="reset-email-info">
                              <p>
                                {/* <Icon type="info" size="15px" style={{ marginLeft: '4px', color: '#0a0000', position: 'absolute', top: '6px', right: '6px'}} /> */}
                                What to do if you did not receive an email:
                              </p>
                              <ul>
                                <li>
                                  Check the spelling of your email and try again:{' '}
                                  <b>{historyState?.state?.username ? <b>{historyState?.state?.username}</b> : ''}</b>
                                </li>
                                <li>Check the spam folder in your email inbox</li>
                                <li>Contact your TruRisk administrator</li>
                                <li>
                                  Contact <a href="mailto:support@trurisk.io">TruRisk support</a>
                                </li>
                              </ul>
                            </div>
                          }
                          content={
                            <div style={{ display: 'inline-block' }}>
                              <Icon type="info" size="15px" style={{ marginLeft: '4px', color: '#0a0000' }} />
                            </div>
                          }
                        />
                      </h2>
                      <p>
                        {t('Check Your email')}
                        {' at '}
                        {historyState?.state?.username ? <b>{historyState?.state?.username}</b> : ''} {t('for password reset code.')}
                      </p>
                    </div>
                  </Box>

                  <Box m={2}>
                    <FormField name="code" label={t('Code')} />
                  </Box>

                  <Box m={2} mt={3}>
                    <FormField name="password" label={t('New Password')} type={FormFieldTypes.PASSWORD} />
                  </Box>

                  <Box m={2}>
                    <PasswordPolicy password={values.password || ''} />
                  </Box>

                  <Box m={2} mt={4}>
                    <Button variant="contained" color="primary" fullWidth type="submit" disabled={!regex_password.test(values.password) || isSubmitting}>
                      {t('Submit')} {isSubmitting && <CircularProgress size={12} />}
                    </Button>
                  </Box>
                  <Box m={2} mt={4}>
                    <Button color="primary" variant="text" onClick={resendCode(values.username)}>
                      {t('Resend Code')}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <AuthenticationFooter />
      <CopyModal open={loading} text={`${t('Getting you set up...')}`} />
    </div>
  );
};

export default ChangeForgottenPassword;
