export default {
  route: 'preprod.trurisk.io',
  aws: {
    region: 'eu-west-1',
    aws_appsync_graphqlEndpoint: 'https://5x5iwfm6bvfbzlo4ua6vfpxe64.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_apiKey: 'da2-x2m2xhwnfbcxxmfwj4wpautmaq',
    cookie_domain: 'preprod.trurisk.io',
  },
  bucket: {
    PRIVATE_UPLOAD_BUCKET: 'tr-trurisk-preprod-document-upload-1',
    PRIVATE_DOWNLOAD_BUCKET: 'tr-trurisk-preprod-document-target-1',
    DOWNLOAD_REPORTS_BUCKET: 'tr-trurisk-preprod-document-renderer-output-1',
    PUBLIC_BUCKET: 'tr-trurisk-preprod-userimages-1',
  },
  GOOGLE_MAP_API_KEY: 'AIzaSyDf9gYmAByusdy4DYoFshXO2lS3SX9wqxM',
};
