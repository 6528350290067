// import { fetchAuthSession } from 'aws-amplify/auth';

// import { CookieStorage } from 'aws-amplify/utils';
// import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const confOld = (environment: any) => ({
//   Auth: {
//     region: 'eu-west-1',
//     identityPoolId: 'eu-west-1:2360c0aa-2934-482b-88a8-ec2f26fa106f',
//     userPoolId: 'eu-west-1_yfLODduFw',
//     userPoolWebClientId: '3rbfdh6acjgeh7thurkeko26gg',
//     mandatorySignIn: false,
//     cookieStorage: {
//       domain: environment.aws.cookie_domain,
//       path: '/',
//       expires: 365,
//       secure: environment.aws.cookie_domain !== 'localhost',,
//     },
//     authenticationFlowType: 'CUSTOM_AUTH',
//   },
//   aws_appsync_graphqlEndpoint: environment.aws.aws_appsync_graphqlEndpoint,
//   aws_appsync_apiKey: environment.aws.aws_appsync_apiKey,
//   aws_appsync_region: environment.aws.region,
//   aws_appsync_authenticationType: 'API_KEY',
//   graphql_headers: async () => {
//     // const { idToken } = await fetchUserAttributes();
//     const session = await fetchAuthSession();
//     return { Authorization: session.tokens?.idToken };
//   },
// });

const conf = (environment: any) => ({
  API: {
    // headers: async () => {
    //   const session = await fetchAuthSession();
    //   return { Authorization: session.tokens?.idToken };
    // },
    GraphQL: {
      endpoint: environment.aws.aws_appsync_graphqlEndpoint,
      apiKey: environment.aws.aws_appsync_apiKey,
      region: environment.aws.region,
      defaultAuthMode: 'API_KEY',
      // graphql_headers: async () => {
      //   // const { idToken } = await fetchUserAttributes();
      //   const session = await fetchAuthSession();
      //   return { Authorization: session.tokens?.idToken };
      // },
    },
  },
  Auth: {
    // authenticationFlowType: 'CUSTOM_AUTH',
    // mandatorySignIn: false,
    // cookieStorage: {
    //   domain: environment.aws.cookie_domain,
    //   path: '/',
    //   expires: 365,
    //   secure: environment.aws.cookie_domain !== 'localhost',,
    // },
    // authenticationFlowType: 'CUSTOM_AUTH',
    authenticationFlowType: 'USER_SRP_AUTH',
    Cognito: {
      mfa: 'CUSTOM_AUTH',
      // identityPoolId: 'eu-west-1:2360c0aa-2934-482b-88a8-ec2f26fa106f',
      // userPoolId: 'eu-west-1_yfLODduFw',
      // userPoolClientId: '3rbfdh6acjgeh7thurkeko26gg',
      // domain: environment.aws.cookie_domain,

      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code', // 'code' | 'link'
      cookieStorage: {
        domain: environment.aws.cookie_domain,
        path: '/',
        expires: 365,
        secure: environment.aws.cookie_domain !== 'localhost',
        sameSite: 'strict',
      },
      // loginWith: {
      //   // OPTIONAL - Hosted UI configuration
      //   oauth: {
      //     domain: environment.aws.cookie_domain,
      //     scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      //     redirectSignIn: [environment.aws.cookie_domain],
      //     redirectSignOut: [environment.aws.cookie_domain],
      //     responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      //   },
      // },
    },
  },
});

export default conf;
