import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import { confirmSignIn, signIn } from 'aws-amplify/auth';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { PasswordPolicy } from '@trurisk/core/components/cosmetics';
import { FormField, FormFieldTypes } from '@trurisk/core/components/forms';
import { CopyModal } from '@trurisk/core/components/standalone';
import { regex_password } from '@trurisk/core/constants/regex';
import AuthenticationFooter from '../components/authenticationFooter/AuthenticationFooter';
import { IAuthInitialValues } from '../index';

interface Props {
  initialValues: IAuthInitialValues;
}

const RequireNewPassword: React.FC<Props> = ({ initialValues }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const historyState = location.state as { [key: string]: string };
  const oldValues = {
    username: initialValues?.username || historyState?.username || '',
    password: initialValues?.password || historyState?.password || '',
  };

  useEffect(() => {
    initialValues.ready = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async ({ password }: any) => {
    debugger;
    const { username, password: oldPassword } = oldValues;
    if (!username) {
      enqueueSnackbar(t('Username is required!'), { variant: 'error' });
    }
    try {
      setLoading(true);
      // await signOut();
      // debugger;
      await signIn({ username: username.trim(), password: oldPassword.trim() });
      debugger;
      // const { isSignedIn, nextStep } = await signIn({ username: username.trim(), password: oldPassword.trim() });
      // await updatePassword({ oldPassword: oldPassword.trim(), newPassword: password.trim() });
      // await Auth.completeNewPassword(user, password.trim());
      // await signIn({ username: username.trim(), password: password.trim() });
      // await confirmSignIn({
      //   challengeResponse: nextStep.signInStep,
      //   options: {
      //     username: username.trim(),
      //     newPassword: password.trim(),
      //     password: password.trim(),
      //     userAttributes: { username: username.trim(), password: password.trim(), newPassword: password.trim() },
      //   },
      // });
      await confirmSignIn({
        challengeResponse: password.trim(),
      });
      setLoading(false);
    } catch (e) {
      const err: any = e;
      setLoading(false);
      if (err?.code === 'InvalidParameterException' || err?.code === 'NotAuthorizedException') {
        enqueueSnackbar(t('Current Password is invalid. Please try again.'), { variant: 'error' });
      } else if (err?.code === 'LimitExceededException') {
        enqueueSnackbar(t('Too many attempts. Please try again later.'), { variant: 'error' });
      } else if (err?.message) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    }
  };

  return (
    <div className="authentication-wrapper authentication-wrapper-476">
      <div className="authentication-outside-container">
        <div className="authentication-navigation">
          <NavLink to="/">
            <ChevronLeft />
            {t('Back to Login')}
          </NavLink>
        </div>
        <div className="authentication-container">
          <div className="authentication-logo">
            <img src="/auth/images/logo/trurisk-logo-black.png" alt="TruRisk" />
          </div>
          <div className="authentication-form">
            <Formik
              enableReinitialize
              initialValues={{
                password: '',
              }}
              validationSchema={Yup.object().shape({})}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <Box m={2}>
                    <h1>{t('Create Password')}</h1>
                    <h3>{t('Please enter a new password.')}</h3>
                  </Box>
                  <Box m={2}>
                    <FormField name="password" label={t('New Password')} type={FormFieldTypes.PASSWORD} />
                  </Box>
                  <Box m={2}>
                    <PasswordPolicy password={values.password || ''} />
                  </Box>
                  <Box m={2} mt={4}>
                    <Button variant="contained" color="primary" fullWidth type="submit" disabled={!regex_password.test(values.password) || isSubmitting}>
                      {t('Create Password')} {isSubmitting && <CircularProgress size={12} />}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <AuthenticationFooter />
      <CopyModal open={loading} text={`${t('Getting you set up...')}`} />
    </div>
  );
};

export default RequireNewPassword;
