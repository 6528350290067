export default {
  route: 'test.trurisk.io',
  simulatedEnvironment: 'test',
  aws: {
    region: 'eu-west-1',
    aws_appsync_graphqlEndpoint: 'https://zj56nfzzw5gq3ccbecoabahkky.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_apiKey: 'da2-xthso5ivpjg4lcldjqyjktugti',
    cookie_domain: 'test.trurisk.io',
  },
  bucket: {
    PRIVATE_UPLOAD_BUCKET: 'tr-trurisk-test-document-upload-1',
    PRIVATE_DOWNLOAD_BUCKET: 'tr-trurisk-test-document-target-1',
    DOWNLOAD_REPORTS_BUCKET: 'tr-trurisk-test-document-renderer-output-1',
    PUBLIC_BUCKET: 'tr-trurisk-test-userimages-1',
  },
  GOOGLE_MAP_API_KEY: 'AIzaSyDf9gYmAByusdy4DYoFshXO2lS3SX9wqxM',
};
