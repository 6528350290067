import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { resetPassword } from 'aws-amplify/auth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Button, CircularProgress } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { FormField } from '@trurisk/core/components/forms';
// import { configureAmplify } from '@trurisk/core/authentication/configureAmplify';
import { configureAmplify } from './configureAmplify';
import environment from '../config/environment';
import { IAuthInitialValues } from '../index';
import AuthenticationFooter from '../components/authenticationFooter/AuthenticationFooter';

interface Props {
  initialValues: IAuthInitialValues;
}

const RequestForgotPasswordCode: React.FC<Props> = ({ initialValues }) => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    initialValues.ready = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async ({ username }: any) => {
    try {
      await configureAmplify(environment, username.trim());
      await resetPassword({ username: username.trim() });
      initialValues.ready = true;
      initialValues.username = username;
      history.push(`/new-password`, { state: { username: username.trim() } });
    } catch (err) {
      // history.push(`/new-password`);
      history.push('/new-password', { state: { username: username.trim() } });

      // console.log(err)
    }
  };

  return (
    <div className="authentication-wrapper">
      <div className="authentication-outside-container">
        <div className="authentication-navigation">
          <NavLink to="/">
            <ChevronLeft />
            {t('Back to Login')}
          </NavLink>
        </div>
        <div className="authentication-container">
          <div className="authentication-logo">
            <img src="/auth/images/logo/trurisk-logo-black.png" alt="TruRisk" />
          </div>
          <div className="authentication-form">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                username: Yup.string().required(t('Username is a required field.')),
              })}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box m={2}>
                    <div>
                      <h2>{t('Forgot Your Password?')}</h2>
                      <p>{t('Enter your username to receive a link to reset your password.')}</p>
                    </div>
                  </Box>
                  <Box m={2}>
                    <FormField name="username" label={t('Username')} onBeforeChange={(val: string) => val.toLowerCase().trim()} />
                  </Box>
                  <Box m={2} mt={4}>
                    <Button variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
                      {t('Send Link')} {isSubmitting && <CircularProgress size={12} />}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <AuthenticationFooter />
    </div>
  );
};

export default RequestForgotPasswordCode;
