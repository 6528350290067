// import { makeStyles, CssBaseline, createMuiTheme, ThemeProvider } from '@mui/material';
// import { fontWeight } from '@material-ui/system';
// import { createMuiTheme } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const palette = {
  root: {
    main: '#868D89', // $tru_grey_3
    light: '#E7EAE8', // $tru_grey_5
    dark: '#383A39', // $tru_grey_2
  },
  primary: {
    main: '#DC1C24', // $tru_primary_3
    light: '#F48574', // $tru_primary_4
    dark: '#9E0E2D', // $tru_primary_1
  },
  secondary: {
    main: '#5E6B84', // $tru_secondary_2
    light: '#95A6C1', // $tru_secondary_5 - 3
    dark: '#152235', // $tru_secondary_1
  },
  background: {
    // default: '#f4f5fd'
    default: '#nnn',
  },
  // action: {
  //   active: "rgba(0, 0, 0, 0.54)",
  //   hover: "rgba(0, 0, 0, 0.08)",
  //   hoverOpacity: 0.08,
  //   selected: "rgba(0, 0, 0, 0.14)",
  //   disabled: "rgba(0, 0, 0, 0.26)",
  //   disabledBackground: "rgba(0, 0, 0, 0.12)"
  // }
};

const theme = createTheme({
  palette,

  typography: {
    fontFamily: 'Inter',
    fontSize: 14,
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
  },

  shape: {
    borderRadius: 4,
  },

  spacing: 8,

  components: {
    MuiAppBar: {
      styleOverrides: {
        // Name of the slot
        root: {
          transform: 'translateZ(0)',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        // Name of the slot
        root: {
          textTransform: 'none',
        },

        text: {
          color: palette.root.main,
          '&:hover': {
            backgroundColor: 'transparent',
            color: palette.root.dark,
          },
        },

        textPrimary: {
          color: palette.primary.main,
          '&:hover': {
            backgroundColor: 'transparent',
            color: palette.primary.dark,
          },
        },
        textSecondary: {
          color: palette.secondary.main,
          '&:hover': {
            backgroundColor: 'transparent',
            color: palette.secondary.dark,
          },
        },
      },
    },
    // MuiFormControl: {
    //   root: {
    //     // width:'100%'
    //   }
    // },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: palette.root.main,
        },
        rail: {
          backgroundColor: palette.secondary.light,
        },

        track: {
          backgroundColor: palette.secondary.main,
        },
        mark: {
          backgroundColor: 'transparent',
        },
        markActive: {
          backgroundColor: 'transparent',
        },

        thumb: {
          backgroundColor: palette.secondary.main,
          '&:hover': {
            boxShadow: '0px 0px 0px 14px rgba(216,222,232,0.3)',
          },
        },

        valueLabel: {
          '& *': {
            background: 'transparent',
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 6,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 6,
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          // getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        },
      },
    },
  },

  // props: {
  //   // MuiButton: {
  //   //   disableRipple: true,
  //   //   variant: 'contained',
  //   //   color: 'primary',
  //   // },
  // }
});

export default theme;
