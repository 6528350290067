import { LanguageSwitcher } from '@trurisk/core/components/standalone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AuthenticationFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="authentication-footer">
      <div>
        <div className="language-selector">
          <LanguageSwitcher action={() => {}} />
        </div>

        <Link to="/terms">{t('Terms of Use')}</Link>
        <Link to="/policy">{t('Privacy Policy')}</Link>
      </div>
      <div>
        <h6>&copy;{new Date().getFullYear()} TruRisk</h6>
      </div>
    </div>
  );
};

export default AuthenticationFooter;
