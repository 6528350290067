import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { IAuthInitialValues } from '../../index';

import './DownloadApp.scss';
import AuthenticationFooter from '../../components/authenticationFooter/AuthenticationFooter';

interface Props {
  initialValues: IAuthInitialValues;
}

const DownloadApp: React.FC<Props> = ({ initialValues }) => {
  useEffect(() => {
    initialValues.ready = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="authentication-wrapper">
      <div className="authentication-outside-container">
        <div className="authentication-navigation">
          <NavLink to="/auth">
            <ChevronLeft />
            Back to Login
          </NavLink>
        </div>
        <div className="authentication-container">
          <div className="authentication-logo">
            <img src="/auth/images/logo/trurisk-logo-black.png" alt="TruRisk" />
          </div>
          <div className="authentication-form">
            <NavLink to="/auth">IOS</NavLink>
            <NavLink to="/auth">android</NavLink>
          </div>
        </div>
      </div>
      <AuthenticationFooter />
    </div>
  );
};

export default DownloadApp;
