export default {
  route: 'dev.trurisk.io',
  aws: {
    region: 'eu-west-1',
    aws_appsync_graphqlEndpoint: 'https://bt5uoo3q6ffhdotowm7tavi6ee.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_apiKey: 'da2-rkjttp2i3jds5bh4xs53ft4xpu',
    cookie_domain: 'dev.trurisk.io',
  },
  bucket: {
    PRIVATE_UPLOAD_BUCKET: 'tr-trurisk-dev-media-document-upload-1',
    PRIVATE_DOWNLOAD_BUCKET: 'tr-trurisk-dev-media-document-target-1',
    DOWNLOAD_REPORTS_BUCKET: 'tr-trurisk-dev-media-document-renderer-output-1',
    PUBLIC_BUCKET: 'tr-trurisk-dev-userimages-1',
  },
  GOOGLE_MAP_API_KEY: 'AIzaSyDf9gYmAByusdy4DYoFshXO2lS3SX9wqxM',
};
