import React, { createContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContext } from '@trurisk/core/commonTypes';
import NewPassword from './NewPassword';
import ChangePassword from './ChangePassword';
import SignIn from './SignIn';
import RequireNewPassword from './RequireNewPassword';
import { IAuthInitialValues } from '../index';
import environment from '../config/environment';
import PolicyPage from '../pages/terms-policy/PolicyPage';
import TermsPage from '../pages/terms-policy/TermsPage';
import DownloadApp from '../pages/downloadApp/DownloadApp';
import MultiFactorAuthentication from './MultiFactorAuthentication';

const appContext = createContext<AppContext>({} as AppContext);

interface Props {
  initialValues: IAuthInitialValues;
}

const AuthenticatorRouter: React.FC<Props> = (props) => (
  <Switch>
    <Route path="/multi-factor-authentication" render={() => <MultiFactorAuthentication {...props} />} />
    <Route path="/download-app" render={() => <DownloadApp {...props} />} />
    <Route path="/new-password" render={() => <NewPassword {...props} />} />
    <Route path="/change-password" render={() => <ChangePassword {...props} />} />
    <Route path="/require-new-password" render={() => <RequireNewPassword {...props} />} />
    <Route path="/policy" render={() => <PolicyPage context={appContext} environment={environment} />} />
    <Route path="/terms" render={() => <TermsPage context={appContext} environment={environment} />} />
    <Route render={() => <SignIn {...props} />} />
  </Switch>
);

export default AuthenticatorRouter;
