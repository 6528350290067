import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import { FormField } from '@trurisk/core/components/forms';
import { RootState } from '@trurisk/core/store/reducers';
import { confirmSignIn } from 'aws-amplify/auth';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import AuthenticationFooter from '../components/authenticationFooter/AuthenticationFooter';
import { IAuthInitialValues } from '../index';

interface Props {
  initialValues: IAuthInitialValues;
}

const MultiFactorAuthentication: React.FC<Props> = ({ initialValues }) => {
  const { t } = useTranslation();
  const usernew: any = useSelector((state: RootState) => state.user, shallowEqual) || null;

  useEffect(() => {
    initialValues.ready = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async ({ code }: any) => {
    try {
      // await Auth.sendCustomChallengeAnswer(
      //   usernew, // Return object from Auth.signIn()
      //   code, // Confirmation code
      //   // 'SOFTWARE_TOKEN_MFA'// mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      // );

      await confirmSignIn({
        challengeResponse: code,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="authentication-wrapper">
      <div className="authentication-outside-container">
        <div className="authentication-navigation">
          <NavLink to="/">
            <ChevronLeft />
            Back to Login
          </NavLink>
        </div>
        <div className="authentication-container">
          <div className="authentication-logo">
            <img src="/auth/images/logo/trurisk-logo-black.png" alt="TruRisk" />
          </div>
          <div className="authentication-form">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                code: Yup.number()
                  .required(t('Invalid code, please re-check the code in your email and try again.'))
                  .typeError(t('Invalid code, please re-check the code in your email and try again.')),
              })}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box m={2}>
                    <div>
                      <h2>{t('Enter one-time code')}</h2>
                      <p>
                        <Trans
                          i18nKey="Please enter the <strong>6 digit</strong> security code sent to your registered email address to approve your login."
                          components={{ bold: <strong /> }}
                        />
                      </p>
                    </div>
                  </Box>
                  <Box m={2}>
                    <FormField type="number" name="code" label={t('Code')} onBeforeChange={(val: string) => val.trim()} />
                  </Box>
                  <Box m={2} mt={4}>
                    <Button variant="contained" color="primary" fullWidth type="submit" disabled={isSubmitting}>
                      {t('Confirm')} {isSubmitting && <CircularProgress size={12} />}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <AuthenticationFooter />
    </div>
  );
};

export default MultiFactorAuthentication;
