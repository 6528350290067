import { AppContext } from '@trurisk/core/commonTypes';
import { ActionBar } from '@trurisk/core/components/skeleton';
import React, { Context } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import Identity from '@trurisk/core/models/identity';
import LegalWrapper from './LegalWrapper';
import './terms-policy.scss';

interface Props {
  context: Context<AppContext>;
  environment: any;
  identity?: Identity;
}

function PolicyPage(props: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { environment } = props;

  const title = t('Privacy Policy');
  const body = <iframe title={title} src={`https://${environment.aws.cookie_domain}/privacy-policy-uk`} width="100%" height="100%" />;
  const footer = (
    <Grid container wrap="nowrap">
      <ActionBar.Button onClick={() => history.goBack()} color="primary" variant="text">
        {t('Go Back')}
      </ActionBar.Button>
    </Grid>
  );

  return <LegalWrapper {...props} title={title} body={body} footer={footer} />;
}

export default PolicyPage;
