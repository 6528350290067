import process from 'process';
import Cookies from 'universal-cookie';
import { signOut as signOutAmplify } from 'aws-amplify/auth';
// import { serviceLinks, serviceLinksLocal } from '../constants/enums';
import { serviceLinks, serviceLinksLocal } from '@trurisk/core/constants/enums';

const cookies = new Cookies();

const forceSignOut = async (environment: any) => {
  debugger;
  // cookies.remove('trurisk_account');
  // await signOutAmplify();
  const cook = await cookies.getAll();
  const keys = Object.keys(cook);
  keys.forEach((c) => {
    cookies.remove(c);
    cookies.remove(c, { domain: `.${environment.route}`, path: '/' });
    cookies.remove(c, { domain: environment.route, path: '/' });
  });
  // const links = process?.env?.REACT_APP_STAGE === 'local' ? serviceLinksLocal : serviceLinks;
  // window.location.href = `${links[0]}logged-out`;
};

export default forceSignOut;
