/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import signOut from '@trurisk/core/authentication/signOut';
import MenuItem from '@mui/material/MenuItem';
import MaterialMenu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { AccountCircleOutlined } from '@mui/icons-material';
import Identity from '@trurisk/core/models/identity';
import getPublicImage from '@trurisk/core/helpers/image';
import './AuthTopBar.scss';

type Props = {
  identity: Identity | null;
  context: any;
  environment: any;
};

function AuthTopBar({ identity, environment }: Props) {
  const identityPopover = identity ? <AuthIdentityPopover identity={identity} environment={environment} /> : null;

  return (
    <div className="auth-topbar-container">
      <div className="auth-topbar-logo">
        <Link to="/">
          <img src="/auth/images/logo/trurisk-logo-black-small.png" alt="logo" />
        </Link>
      </div>
      <div className="topbar-right">{identityPopover}</div>
    </div>
  );
}

export default AuthTopBar;

interface IdentityPopoverProps {
  identity: Identity;
  environment: any;
}

function AuthIdentityPopover({ identity, environment }: IdentityPopoverProps) {
  const { t } = useTranslation();
  const [avatarImage, setAvatarImage] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (identity.avatar_image) {
      // loadImageFromS3(`small/${identity.avatar_image}`, environment.bucket.PRIVATE_DOWNLOAD_BUCKET, '').then(setAvatarImage);
      setAvatarImage(getPublicImage(environment, identity.avatar_image));
    } else {
      setAvatarImage('');
    }
  }, [identity, environment]);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className="identity-popover-container">
      <div className="menu-container">
        <Button aria-label="more" aria-controls="long-menu" aria-haspopup="true" variant="text" color="inherit" onClick={handleClick}>
          {avatarImage ? <Avatar alt={identity.name} src={avatarImage} /> : <AccountCircleOutlined />}
          {identity.name}
        </Button>

        <MaterialMenu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: -10, horizontal: 0 }}
          classes={{ paper: `menu-container identity-popover-menu` }}
        >
          <MenuItem classes={{ root: 'menu-item' }} onClick={handleClose}>
            <div onClick={() => signOut(environment)}>{t('Logout')}</div>
          </MenuItem>
        </MaterialMenu>
      </div>
    </div>
  );
}
