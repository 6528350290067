import { AppContext } from '@trurisk/core/commonTypes';
import Identity from '@trurisk/core/models/identity';
import React, { Context, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { LinearLoader } from '@trurisk/core/components/cosmetics';
import SomthingWentWrongPage from '@trurisk/core/pages/somthingWentWrongPage/SomthingWentWrongPage';
import environment from './config/environment';
import SelectService from './pages/selectService/SelectService';
import PolicyPage from './pages/terms-policy/PolicyPage';
import TermsPage from './pages/terms-policy/TermsPage';

interface RootState {
  identity: Identity;
}

interface Props {
  context: Context<AppContext>;
  authError: boolean;
}

const Router: React.FC<Props> = ({ context, authError }) => {
  // (window as any).LOG_LEVEL = 'DEBUG'; //log amplify // test pull request
  // console.log('process.env.APP_ENV = ', process.env.NODE_ENV)
  const { t } = useTranslation();
  const history = useHistory();
  const identity: Identity = useSelector((state: RootState) => state.identity, shallowEqual) || null;
  // console.log('identity = ', identity);

  useEffect(() => {
    if (identity?.id && !identity.tou_accepted) {
      history.push('/terms');
    }
  }, [history, identity]);

  // console.log('-------------2');
  // console.log('authError', authError);
  // console.log('identity', identity);

  if (authError) {
    return <Route path="/" render={() => <SomthingWentWrongPage environment={environment} t={t} />} />;
  }
  if (identity?.id) {
    return (
      <Switch>
        <Route path="/terms" render={() => <TermsPage identity={identity} context={context} environment={environment} />} />
        <Route path="/policy" render={() => <PolicyPage identity={identity} context={context} environment={environment} />} />
        {identity?.tou_accepted && <Route render={() => <SelectService identity={identity} t={t} environment={environment} />} />}
      </Switch>
    );
  }

  return <LinearLoader absolute />;
};

export default Router;
