/* eslint-disable no-bitwise */
import React, { useEffect, useContext, useState, Context } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppContext, DefaultRouteProps } from '@trurisk/core/commonTypes';

import { Button } from '@mui/material';

import Identity from '@trurisk/core/models/identity';
import { services, serviceLinks, serviceLinksLocal } from '@trurisk/core/constants/enums';
import signOut from '@trurisk/core/authentication/signOut';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { Environment } from '@trurisk/core/commonTypes/environment';
import './SelectService.scss';

interface Props {
  identity: Identity;
  t: Function;
  environment: Environment;
}

function SelectService({ identity, t, environment }: Props) {
  const [ready, setReady] = useState<boolean>(false);
  const links = environment.local ? serviceLinksLocal : serviceLinks;
  const hasAccessToMultipleService =
    (identity.services &&
      typeof identity.services === 'number' &&
      identity.services &&
      identity.services
        .toString(2)
        .split('')
        .filter((a: string) => a === '1').length > 1) ||
    false;
  const cookies = new Cookies();

  useEffect(() => {
    const query = queryString.parse(window.location.search) as any;
    if (query.redirect) {
      window.location.href = query.redirect;
    } else if (!hasAccessToMultipleService) {
      window.location.href = links[identity.services as number];
    } else {
      const serviceFromCookies = cookies.get('trurisk_service');
      if (serviceFromCookies) {
        window.location.href = links[serviceFromCookies as number];
      } else {
        setReady(true);
      }
    }
  }, [hasAccessToMultipleService, identity.services, links, cookies]);

  const navigate = (destination: number) => {
    cookies.set('trurisk_service', destination);
    window.location.href = links[destination] as string;
  };

  return (
    <div className="select-service-wrapper">
      {ready && (
        <div className="select-service-container">
          <p>{t('Select service')}</p>

          {identity.services &&
            typeof identity.services === 'number' &&
            identity.services > 0 &&
            services.toArray().map((item: any) => {
              if (item.key > 0 && ((identity.services as number) & item.key) === item.key) {
                return (
                  <Button key={item.key} variant="outlined" size="medium" onClick={() => navigate(item.key)}>
                    {item.value}
                  </Button>
                );
              }
              return '';
            })}

          <NavLink to="/auth" onClick={() => signOut(environment)}>
            {t('Logout')}
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default SelectService;
